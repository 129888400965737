<template>
  <layoutContainer>
    <div class="container-box">
      <detailPageCommonHeader currentTitle="新增案例" backTitle="返回"></detailPageCommonHeader>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm">
        <el-form-item label="案例名称" prop="name">
          <el-input
            v-model="ruleForm.name"
            maxlength="20"
            placeholder="请输入案例名称（最少2个字，最多20个字）"
          ></el-input>
        </el-form-item>
        <el-form-item label="案例类型" prop="businessId">
          <buttonRadioGroup :value.sync="ruleForm.businessId" :businessList="BusinessList.softwareDevelopmentList" />
        </el-form-item>
        <el-form-item label="案例简介" prop="introduction">
          <el-input
            type="textarea"
            v-model="ruleForm.introduction"
            rows="3"
            maxlength="120"
            placeholder="请输入案例简介（最少2个字，最多20个字）"
          ></el-input>
        </el-form-item>
        <el-form-item label="案例缩略图" prop="lessenCasePicUrl">
          <p>案例缩略图指在案例列表中显示</p>
          <uploadTemplate
            @uploadCallBack="uploadCallBack1"
            @deletePic="ruleForm.lessenCasePicUrl = ''"
            :fileList="[ruleForm.lessenCasePicUrl]"
          ></uploadTemplate>
        </el-form-item>
        <el-form-item label="案例图片" prop="casePicUrl">
          <p>案例图片指在案例详情中显示</p>
          <uploadTemplate
            @uploadCallBack="uploadCallBack"
            @deletePic="i => ruleForm.casePicUrl.splice(i, 1)"
            :fileList="ruleForm.casePicUrl"
          ></uploadTemplate>
        </el-form-item>
      </el-form>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <center>
        <button class="submitButton" @click="validateQueryParams">新增案例</button>
      </center>
    </div>
  </layoutContainer>
</template>

<script>
import {companyCaseCreateApi} from '../../api/company'
import {mapState} from 'vuex'

export default {
  computed: {
    ...mapState(['UserInfo', 'BusinessList'])
  },
  data() {
    return {
      type: '',
      dialogVisible: false,
      dialogImageUrl: '',
      activeIndex1: 1,
      disabled: false,
      ruleForm: {
        companyId: 1, // 公司id
        name: '', // 案例名
        businessId: 1, // 承接业务id，1-网站建设，2-app开发，3-小程序开发
        introduction: '', // 案例介绍
        casePicUrl: [], // 案例图url列表
        lessenCasePicUrl: '' // 案例缩略图url
      },
      rules: {
        name: [
          {required: true, message: '请输入案例名称', trigger: 'blur'}
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ], // 案例名
        introduction: [
          {required: true, message: '请输入案例名称', trigger: 'blur'}
          // {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
        ], // 案例介绍
        casePicUrl: [{required: true, message: '请上传案例图片'}], // 案例图url列表
        lessenCasePicUrl: [{required: true, message: '请上传案例缩略图'}] // 案例缩略图url
      }
    }
  },
  created() {
    this.ruleForm.companyId = this.$route.query.id
    console.log(' this.$route.query.id', this.$route.query.id)
  },
  methods: {
    validateQueryParams() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.submit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    submit() {
      companyCaseCreateApi({...this.ruleForm}).then(res => {
        this.$message('新增案例成功')
        this.$router.go(-1)
      })
    },
    uploadCallBack(res) {
      console.log(res.data)
      this.ruleForm.casePicUrl.push(res.data)
    },
    uploadCallBack1(res) {
      console.log(res.data)
      this.ruleForm.lessenCasePicUrl = res.data
    },

    handlePictureCardPreview(imgUrl) {
      this.dialogImageUrl = imgUrl
      // this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.container-box {
  padding-top: 40px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 150px;
  background: #ffffff;
  /deep/ .el-form-item__label {
    font-size: 18px !important;
    padding: 0 20px 0 0;
  }
  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
}

.submitButton {
  margin-top: 30px;
  width: 370px;
  height: 46px;
  border: none;
  outline: none;
  background: #5ad3cd;
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
}
</style>
