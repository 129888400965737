<template>
  <layoutContainer>
    <div class="introduction">
      <div class="main-message-box">
        <div class="button-box">
          <button v-if="isOwner || UserInfo.isManage" @click="dialogVisible = true">
            <img src="/img/companyAdd.png" alt="" /> 新增管理员
          </button>
          <button v-if="isOwner || UserInfo.isManage" class="button-right" @click="editCompanyCase">
            <img src="/img/companyChange.png" alt="" /> 修改信息
          </button>
        </div>
        <div class="left">
          <img class="logo-img" :src="detail.smallLogoPicUrl" alt="ss" />
        </div>
        <div class="right">
          <h4>{{ detail.shortName }}</h4>
          <p>
            公司全称：<span>{{ detail.name }}</span>
          </p>
          <p>
            公司地址：<span @click="toBaiduPage(AreaListFilterMap(detail.provinceId) + detail.address)"
              >{{ detail.address }}<img src="/img/companyAddress.png" alt=""
            /></span>
          </p>
          <p>
            成立时间：<span>{{ detail.establishDate | formatTimeFilter('YYYY-MM-DD') }}</span>
          </p>
          <p>
            所在地区：<span>{{ detail.province }}</span>
          </p>
          <div class="detail-content">
            <div class="detail-bassic-info">
              <p>
                客户数量：<span>{{ detail.customerNum }}</span>
              </p>
              <p>
                案例数量：<span>{{ detail.ownCaseNum }}</span>
              </p>
              <p>
                员工人数：<span>{{ detail.employeeNum }}</span>
              </p>

              <p class="list-box">
                业务范围：<domaintag v-for="(item, i) in detail.businessList" :key="i" :text="item.name"></domaintag>
              </p>
            </div>
            <div class="content-detail">
              <p v-if="detail.hasWebSiteUrl">
                官网：
                <span class="check-contact" v-if="!(hasEffected || isOwner || UserInfo.isManage)" @click="getVip"
                  >查看</span
                >
                <span class="website-url" v-if="hasEffected || isOwner || UserInfo.isManage">
                  <a :href="detail.websiteUrl" target="_blank" class="website-url">{{ detail.websiteUrl }}</a>
                </span>
              </p>
              <p v-if="detail.hasPhoneNumber">
                电话：
                <span class="check-contact" v-if="!(hasEffected || isOwner || UserInfo.isManage)" @click="getVip"
                  >查看</span
                >
                <span v-if="hasEffected || isOwner || UserInfo.isManage">{{ detail.phoneNumber }}</span>
              </p>
              <p v-if="detail.hasWeChat">
                微信：
                <span class="check-contact" v-if="!checkWechat" @click="checkWechatUrl">查看</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="intrucetion-detail-text">
        <p>公司简介</p>
        <span> {{ detail.introduction }}</span>
      </div>
    </div>
    <div class="good-case" v-if="isOwner || UserInfo.isManage || productList.length > 0">
      <h4>
        <span>产品</span>
        <div class="button-case-add-info" @click="addCompanyCase('product')" v-if="isOwner || UserInfo.isManage">
          <p>新增</p>
          <img src="../../assets/addInfo.png" class="add-info" alt="" srcset="" />
        </div>
      </h4>
      <el-divider></el-divider>
      <div class="content">
        <div class="content-product" v-for="item in productList" :key="item.id">
          <div class="tatle-bar">
            <p class="title">
              <el-tooltip
                effect="dark"
                popper-class="title-content-item"
                :content="`${item.productName}/${item.businessName}`"
                placement="top-start"
              >
                <div style="display: inline-block;">
                  <p class="title-content">
                    {{ item.productName }}<span class="business-name">/{{ item.businessName }}</span>
                  </p>
                </div>
              </el-tooltip>

              <el-tooltip
                class="item"
                effect="dark"
                popper-class="item"
                :content="item.businessDescription"
                placement="top-start"
                v-if="item.businessDescription"
              >
                <i class="el-icon-question business-desc"></i>
                <img src="../../assets/comQuestion.png" class="business-desc" />
              </el-tooltip>
            </p>
            <div class="action">
              <div class="website" @click="turnWechatUrl" v-if="detail.hasWebSiteUrl">官网介绍</div>
              <!-- <i class="el-icon-edit-outline edit" v-if="item.hasAuth"></i> -->
              <!-- <i class="el-icon-circle-close del" @click="delProduct(item.id)" v-if="item.hasAuth"></i> -->
              <img
                src="../../assets/editcom.png"
                alt=""
                class="edit"
                v-if="item.hasAuth"
                @click="editProduct(item.id)"
              />
              <img src="../../assets/delCom.png" alt="" class="del" @click="delProduct(item.id)" v-if="item.hasAuth" />
            </div>
          </div>
          <el-tooltip
            class="item-desc"
            effect="dark"
            popper-class="item-desc"
            :content="item.introduction"
            placement="top-start"
          >
            <p class="desc">{{ item.introduction }}</p>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div class="good-case" v-if="isOwner || UserInfo.isManage || serveList.length > 0">
      <h4>
        <span>服务</span>
        <span class="serve-website" @click="turnWechatUrl" v-if="detail.hasWebSiteUrl">官网介绍</span>
        <div class="button-case-add-info" @click="addCompanyCase('serve')" v-if="isOwner || UserInfo.isManage">
          <p>新增</p>
          <img src="../../assets/addInfo.png" class="add-info" alt="" srcset="" />
        </div>
      </h4>
      <el-divider></el-divider>
      <div class="serve-content">
        <div class="serve-item" v-for="item in serveList" :key="item.id">
          <span class="serve-title">{{ item.serviceName }}</span>
          <el-tooltip
            popper-class="item"
            effect="dark"
            :content="item.businessDescription"
            placement="top-start"
            v-if="item.businessDescription"
          >
            <img src="../../assets/comQuestion.png" class="serve-desc" />
          </el-tooltip>
          <img
            src="../../assets/editcom.png"
            alt=""
            class="serve-edit"
            v-if="item.hasAuth"
            @click="editService(item.id)"
          />
          <img
            src="../../assets/delCom.png"
            alt=""
            class="serve-del"
            @click="delService(item.id)"
            v-if="item.hasAuth"
          />
        </div>
      </div>
    </div>
    <div class="good-case">
      <h4>
        <span>案例</span>
        <button class="button-case-add" @click="addCompanyCase" v-if="isOwner || UserInfo.isManage">
          新增案例
        </button>
      </h4>
      <h5 v-if="list.length">网站</h5>
      <ul class="img-container" id="list">
        <draggable @end="end" v-model="list" class="drag1" :options="{disabled: !isOwner || !UserInfo.isManage}">
          <transition-group>
            <pcItemWrapper
              :detailPageUrl="`/company-case-detail/${item.id}`"
              v-for="(item, i) in list"
              :key="i"
              :item="item"
              v-if="item.businessId != 2 && item.businessId != 3"
              imgKey="thumbnail"
              :isOwnCompany="isOwner || UserInfo.isManage"
              class="pcItemWrapper"
              @deleteCase="companyCaseDelete"
            />
          </transition-group>
        </draggable>
      </ul>
      <h5 v-if="list.length" style="margin-top:10px">app</h5>
      <ul class="img-container">
        <draggable @end="end" v-model="list" class="drag2" :options="{disabled: !isOwner || !UserInfo.isManage}">
          <transition-group>
            <appItemWrapper
              :detailPageUrl="`/company-case-detail/${item.id}`"
              v-for="(item, i) in list"
              v-if="item.businessId == 2 || item.businessId == 3"
              :key="i"
              :item="item"
              imgKey="thumbnail"
              :isOwnCompany="isOwner || UserInfo.isManage"
              @deleteCase="companyCaseDelete"
            />
          </transition-group>
        </draggable>
      </ul>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>
        <el-input v-model="mobile" placeholder="请输入对方的帐号，确认对方成为管理员"></el-input>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="checkWechat" width="300px">
      <img :src="detail.wechatPicUrl" style="width: 100%;" alt="" srcset="" />
    </el-dialog>
    <vip :showVipPage="showVipPage" @dialogClose="dialogClose"></vip>
  </layoutContainer>
</template>

<script>
import {mapState} from 'vuex'
import {toBaiduPage} from '@/utils'
import {getCompanyProductList, getCompanyServeList, checkVip, delService, delProduct} from '../../api/product'
import {
  getCompanyDetailApi,
  companyAdminAddApi,
  getcompanyAdminApi,
  companyCaseDeleteApi,
  companyCaseSortApi
} from '../../api/company'
import draggable from 'vuedraggable'
import vip from '../../components/vip/index.vue'

export default {
  computed: {
    ...mapState(['UserInfo', 'AreaList', 'BusinessList', 'CompanyList', 'EducationList', 'EmployeeDomainList'])
  },
  components: {
    draggable,
    vip
  },
  data() {
    return {
      dialogVisible: false,
      mobile: '',
      companyId: this.$route.params.id || '',
      adminId: undefined,
      list: Object.freeze([
        {
          name: 'asdfasdf'
        }
      ]),
      isOwner: false,
      detail: {
        id: 1,
        name: '',
        shortName: '',
        status: null,
        provinceId: 1,
        establishDate: '',
        settleTime: '',
        address: '',
        introduction: '',
        employeeNum: 0,
        customerNum: 0,
        logoPicUrl: '',
        smallLogoPicUrl: '',
        businessId: [],
        ownCaseNum: undefined
      },
      mokeServeList: [],
      productList: [],
      serveList: [],
      hasEffected: false,
      checkWechat: false,
      showVipPage: false // 是否显示vip弹窗
    }
  },
  created() {
    this.companyId = this.$route.params.id
    this.adminId = this.UserInfo.id
    console.log('%c 路由携带公司详情....', 'color: red; font-style: italic', JSON.stringify(this.detail, null, 2))
    this.getCompanyDetail()
    this.getcompanyAdmin()
    checkVip().then(res => {
      this.$nextTick(() => {
        this.hasEffected = res.data?.hasEffected || false
      })
    })
  },
  mounted() {},
  methods: {
    dialogClose(value) {
      this.showVipPage = false
      if (value) {
        checkVip().then(res => {
          this.hasEffected = res.data.hasEffected
        })
      }
    },
    toBaiduPage,
    getCompanyDetail() {
      if (!this.companyId) return
      const params = {
        companyId: this.companyId
      }
      getCompanyDetailApi(params).then(res => {
        const resArr = res.data.customizedCompanyOwnCaseList.sort((a, b) => a.sequenceNum - b.sequenceNum)
        this.list = [...resArr]
        this.deepList = [...resArr]
        const arr = []
        const idList = []
        res.data.businessList.forEach(item => {
          if (!idList.includes(item.id)) {
            idList.push(item.id)
            arr.push(item)
          }
        })
        this.detail = {
          ...res.data.companyVo,
          businessList: arr,
          phoneNumber: res.data.phoneNumber,
          websiteUrl: res.data.websiteUrl,
          wechatPicUrl: res.data.wechatPicUrl,
          province: res.data.areaList.map(item => item.name).join('  ')
        }
        this.$nextTick(() => {})
      })
      getCompanyProductList(params).then(res => {
        this.productList = res.data
      })
      getCompanyServeList(params).then(res => {
        this.serveList = res.data
      })
    },
    addCompanyCase(type) {
      switch (type) {
        case 'product':
          this.$router.push({
            path: '/product/detail',
            query: {
              companyId: this.detail.id,
              productEdit: false
            }
          })
          break
        case 'serve':
          this.$router.push({
            path: '/serve/detail',
            query: {
              companyId: this.detail.id,
              serveEdit: false
            }
          })
          break
        default:
          this.$router.push({
            path: `/company-case-add`,
            query: {
              id: this.detail.id
            }
          })
      }
    },
    editCompanyCase() {
      // this.$router.push('/companyEnter')
      this.$router.push({
        path: `/companyEnter`,
        query: {
          companyId: this.detail.id
        }
      })
    },
    handleClose() {
      this.dialogVisible = false
      companyAdminAddApi({
        companyId: this.detail.id,
        username: this.mobile
      })
        .then(res => {
          this.$message(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    getcompanyAdmin() {
      if (!this.UserInfo?.id) return
      getcompanyAdminApi({
        userId: this.UserInfo.id
      }).then(res => {
        if (res.data && res.data.companyId == this.companyId) {
          this.isOwner = true
        } else {
          this.isOwner = false
        }
      })
    },
    datadragEnd(evt) {
      evt.preventDefault()
      console.log('拖动前的索引 :' + evt.oldIndex)
      console.log('拖动后的索引 :' + evt.newIndex)
      // console.log(this.colors) //拖拽排序后会改变原先数据索引(排序)
      // console.log('获取拖拽改变数据索引后，拖动前的索引的元素 :' + this.colors[evt.oldIndex])
      // console.log('获取拖拽改变数据索引后，被拖拽的元素 :' + this.colors[evt.newIndex])
    },
    companyCaseDelete(id) {
      this.$confirm('确认删除吗？')
        .then(() => {
          companyCaseDeleteApi({
            caseId: id
          })
            .then(res => {
              this.getCompanyDetail()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(_ => {})
    },
    end(evt) {
      console.log(evt, 'end....')
      this.drag = true
      evt.item //可以知道拖动的本身
      evt.to // 可以知道拖动的目标列表
      evt.from // 可以知道之前的列表
      evt.oldIndex // 可以知道拖动前的位置
      evt.newIndex // 可以知道拖动后的位置
      // console.log(
      //   '%c res....',
      //   'color: red; font-style: italic',
      //   evt.item,
      //   '\n',
      //   evt.to,
      //   '\n',
      //   evt.from,
      //   '\n',
      //   evt.oldIndex,
      //   '\n',
      //   evt.newIndex
      // )
      console.log(
        '%c res....',
        'color: red; font-style: italic',
        evt.oldIndex,
        evt.newIndex,
        this.list.map(item => item.id)
      )
      // console.log(
      //   '%c res....',
      //   'color: red; font-style: italic',
      //   JSON.stringify({caseId: this.detail.id, picUrl: this.list.map(item => item.thumbnail)}, null, 2)
      // )
      const arr = this.deepList.filter((item, i) => {
        return item.id != this.list[i].id
      })
      const sequenceNum1 = arr[0]?.sequenceNum
      const sequenceNum2 = arr[1]?.sequenceNum
      arr[0].sequenceNum = sequenceNum2
      arr[1].sequenceNum = sequenceNum1
      console.log('%c res排序救过....', 'color: red; font-style: italic', JSON.stringify(arr, null, 2))
      console.table(
        this.list.map(item => {
          return {
            id: item.id,
            sequenceNum: item.sequenceNum
          }
        })
      )
      console.table(
        this.deepList.map(item => {
          return {
            id: item.id,
            sequenceNum: item.sequenceNum
          }
        })
      )
      console.log(
        '%c res....',
        'color: red; font-style: italic',
        JSON.stringify(
          this.deepList.map(item => {
            return {
              id: item.id,
              sequenceNum: item.sequenceNum
            }
          }),
          null,
          2
        )
      )
      companyCaseSortApi({
        companyId: this.detail.id,
        caseSeqModelList: this.deepList.map(item => {
          return {
            caseId: item.id,
            sequenceNum: item.sequenceNum
          }
        })
      }).then(res => {
        console.log('%c 排序结果....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
        this.getCompanyDetail()
      })
      // TODO：修改顺序成功之后重新获取列表
    },
    AreaListFilterMap(val) {
      const ss = localStorage.getItem('AreaList')
      const AreaList = ss ? JSON.parse(ss) : []
      if (!AreaList.length) return ''
      return AreaList.filter(item => item.id == val && item.level == 2)[0]?.name || ''
    },
    getVip() {
      if (!this.UserInfo || !this.UserInfo.id) {
        this.$router.push('/login')
        return
      }

      console.log('展示vip 充值')
      this.showVipPage = true
    },
    checkWechatUrl() {
      if (!this.UserInfo || !this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      if (this.isOwner || this.UserInfo.isManage || this.hasEffected) {
        this.checkWechat = true
      } else {
        this.showVipPage = true
        console.log('展示vip 充值')
      }
    },
    turnWechatUrl() {
      if (!this.UserInfo || !this.UserInfo.id) {
        this.$router.push('/login')
        return
      }
      if (this.isOwner || this.UserInfo.isManage || this.hasEffected) {
        window.open(this.detail.websiteUrl)
      } else {
        this.showVipPage = true
        console.log('展示vip 充值')
      }
    },
    delService(id) {
      this.$confirm('确认删除该服务么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delService(id).then(res => {
          const params = {
            companyId: this.companyId
          }
          this.getCompanyDetail()
          getCompanyServeList(params).then(res => {
            this.serveList = res.data
          })
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      })
    },
    editService(id) {
      this.$router.push({
        path: '/serve/detail',
        query: {
          companyId: this.detail.id,
          serviceId: id,
          serveEdit: true
        }
      })
    },
    delProduct(id) {
      this.$confirm('确认删除该产品么', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delProduct(id).then(res => {
          this.getCompanyDetail()
          const params = {
            companyId: this.companyId
          }
          getCompanyProductList(params).then(res => {
            this.productList = res.data
          })
          this.$message({
            message: '删除成功',
            type: 'success'
          })
        })
      })
    },
    editProduct(id) {
      this.$router.push({
        path: '/product/detail',
        query: {
          companyId: this.detail.id,
          productId: id,
          productEdit: true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.drag {
  width: 100%;
  // overflow: hidden;
}

.drag1 > span {
  div {
    display: inline-block;
  }

  > div:nth-child(5n + 2),
  > div:nth-child(5n + 3),
  > div:nth-child(5n + 4),
  > div:nth-child(5n + 5) {
    margin-left: 50px;
  }
}
.drag2 > span {
  div {
    display: inline-block;
  }

  > div:nth-child(4n + 2),
  > div:nth-child(4n + 3),
  > div:nth-child(4n + 4) {
    margin-left: 50px;
  }
}
.introduction {
  background: #ffffff;
  padding: 30px 40px;
  margin: 20px 0;
}

.main-message-box {
  display: flex;
  position: relative;

  .logo-img {
    width: 300px;
    height: 225px;
    border-radius: 5px;
    margin-right: 38px;
  }

  .right {
    h4 {
      margin-top: 10px;
      margin-bottom: 5px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      height: 28px;
      line-height: 28px;
    }

    p {
      line-height: 40px;
      font-size: 18px;
      font-weight: 400;
      color: #999999;
      font-family: PingFangSC-Regular, PingFang SC;
      vertical-align: top;
      span {
        color: #333333;
      }
      img {
        vertical-align: middle;
        height: 20px;
        margin-left: 10px;
      }
    }

    .list-box {
      span {
        display: inline-block;
        height: 24px;
        line-height: 24px;
        font-size: 13px;
        padding: 0 16px;
        border-radius: 12px;
        // border: 1px solid #000;
        background: #f6f6f6;
        margin-right: 10px;
      }
    }
  }
}

hr {
  margin-top: 25px;
  margin-bottom: 25px;
}

.intrucetion-detail-text {
  p {
    height: 55px;
    line-height: 55px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  span {
    display: block;
    // text-indent: 33px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}

.title-content {
  max-width: 246px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.title-content-item {
  width: 390px;
}
.button-box {
  position: absolute;
  right: 0;
  top: 0;
  button {
    margin-left: 20px;
    width: 124px;
    height: 40px;
    border-radius: 4px;
    border: 2px solid #5ad3cd;

    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #5ad3cd;

    background: #ffffff;
    img {
      height: 20px;
      vertical-align: middle;
    }
  }
}

.good-case {
  margin-top: 30px;
  background: #ffffff;
  padding: 30px 40px;
  margin: 20px 0;

  h4 {
    position: relative;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }

  h5 {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.pcItemWrapper {
  position: relative;
}
.button-case-add {
  position: absolute;
  right: 0;
  top: 0;
  width: 110px;
  height: 40px;
  background: rgba(56, 194, 187, 0.1);
  border-radius: 4px;
  border: 1px solid #37c2bc;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #37c2bc;
  cursor: pointer;
}

.button-case-add-info {
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  cursor: pointer;
  p {
    vertical-align: top;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff724c;
    line-height: 30px;
    display: inline-block;
  }
  .add-info {
    display: inline-block;
    margin-left: 6px;
    width: 26px;
    height: 27px;
    vertical-align: top;
    margin-top: 3px;
  }
}

hr {
  border: 1px solid #dedede;
  border-top: none;
}
.content {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.content-product {
  display: inline-block;
  width: 540px;
  height: 140px;
  margin-bottom: 20px;
  background: rgba(246, 246, 248, 0.5);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 25px 24px;
}
.tatle-bar {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.title {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 30px;
  text-align: left;
}
.desc {
  width: 490px;
  height: 36px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.business-name {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}
.action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.website {
  border-radius: 15px;
  border: 1px solid #5ad3cd;
  height: 30px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5ad3cd;
  line-height: 28px;
  box-sizing: border-box;
  text-align: center;
  margin-right: 18px;
  width: 86px;
  cursor: pointer;
}
.edit {
  width: 28px;
  height: 28px;
  margin-right: 18px;
  font-size: 28px;
  cursor: pointer;
}
.business-desc {
  width: 24px;
  height: 24px;
  font-size: 24px;
  margin-left: 12px;
  cursor: pointer;
  margin-top: 4px;
  vertical-align: top;
}
.del {
  width: 28px;
  height: 28px;
  margin-right: 0;
  font-size: 28px;
  cursor: pointer;
}
.serve-website {
  display: inline-block;
  width: 100px;
  height: 36px;
  background: rgba(90, 211, 205, 0.15);
  border-radius: 18px;
  opacity: 0.78;
  border: 1px solid #5ad3cd;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5ad3cd;
  line-height: 34px;
  text-align: center;
  margin-left: 10px;
  cursor: pointer;
}
.serve-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.serve-item {
  padding: 16px 24px;
  height: 66px;
  background: rgba(246, 246, 248, 0.5);
  border-radius: 44px;
  margin-right: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.serve-title {
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 33px;
  display: inline-block;
  margin-right: 12px;
}
.serve-desc {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-left: 12px;
  font-size: 24px;
  cursor: pointer;
}
.serve-edit {
  width: 28px;
  height: 28px;
  margin-right: 12px;
  font-size: 28px;
  cursor: pointer;
}
.serve-del {
  width: 28px;
  height: 28px;
  margin-right: 12px;
  font-size: 28px;
  cursor: pointer;
}
.detail-content {
  display: flex;
  justify-content: flex-start;
}
.detail-bassic-info {
  width: 464px;
}
.content-detail {
  width: 327px;
  vertical-align: top;
}
.website-url {
  width: 261px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  height: 40px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5ad3cd;
  line-height: 40px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}
.check-contact {
  width: 60px !important;
  background: rgba(56, 194, 187, 0.1);
  border-radius: 14px;
  border: 1px solid #37c2bc;
  font-size: 16px !important;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #5ad3cd !important;
  line-height: 24px;
  display: inline-block !important;
  text-align: center;
  word-break: normal;
  cursor: pointer;
}
</style>
<style>
.item {
  max-width: 200px;
}
.item-desc {
  width: 492px;
}
</style>
